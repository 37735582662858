/** @jsxImportSource @emotion/react */
import {
	FormControl,
	FormHelperText,
	InputBase,
	inputBaseClasses,
	InputLabel,
	MenuItem,
	Select,
	selectClasses,
	styled,
	useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const CustomInputBaseSelect = styled(InputBase)(({ theme, size }) => ({
	borderRadius: 12,

	[`& .${inputBaseClasses.input}`]: {
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		fontSize: 18,
		lineHeight: "22px",
		padding: size === "small" ? "10px 12px" : "18px 16px",
		color: theme.palette.text.primary,
		border: "1px solid rgba(0, 0, 0, 0.12)",
		borderRadius: "12px !important",
	},
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
	padding: "20px 30px",
	fontSize: 16,
}));

export type FlatSelectProps = {
	label?: string;
	choices: { name: string; value: string; description?: string; hidden?: boolean }[];
	onChange: (value: string) => void;
	initialValue?: string;
	value?: string;
	onBlur?: () => void;
	disabled?: boolean;
	displayEmpty?: boolean;
	emptyText?: string;
	placeholder?: string;
	helperText?: string;
	size?: "small" | "medium";
};

const FlatSelect = (props: FlatSelectProps) => {
	const { label, choices, onChange, initialValue, size } = props;
	const [value, setValue] = useState((initialValue || props.value) ?? "any");

	useEffect(() => {
		if (props.value && props.value !== value) setValue(props.value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.value]);

	const theme = useTheme();

	return (
		<FormControl disabled={props?.disabled} sx={{ width: "100%", pointerEvents: props.disabled ? "none" : undefined }}>
			{label && (
				<InputLabel
					shrink={true}
					sx={{
						color: theme.palette.text.secondary,
						fontSize: 20,
						whiteSpace: "nowrap",
						transform: "translate(0px, 0px) scale(0.75);",
						"&.Mui-focused": {
							color: theme.palette.text.secondary,
						},
					}}
				>
					{label}
				</InputLabel>
			)}
			<Select
				disabled={props.disabled}
				onBlur={props.onBlur}
				sx={{
					width: "100%",
					paddingTop: 0,
					paddingBottom: 0,
					marginTop: label ? 6 : 0,
					"div > div > span": {
						display: "none",
					},
					svg: {
						right: 0,
						marginRight: "10px",
						fill: (theme) => theme.palette.text.primary,
					},
					[`& .${selectClasses.select}`]: {
						minHeight: "unset !important",
						letterSpacing: "normal",
					},
				}}
				value={value}
				onChange={(e) => {
					setValue(e.target.value as string);
					onChange?.(e.target.value as string);
				}}
				input={<CustomInputBaseSelect size={size} />}
				IconComponent={ExpandMoreIcon}
				displayEmpty
				MenuProps={{
					PaperProps: {
						sx: {
							borderRadius: "12px",
						},
					},
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left",
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left",
					},
					// anchorEl: null,
				}}
			>
				{props?.displayEmpty && <StyledMenuItem value="" children={props?.emptyText ?? "Seleziona un valore"} />}
				{choices.map((choice, index) => {
					if (choice.hidden) return null;
					return (
						<StyledMenuItem
							key={`choice_${index}`}
							value={choice.value}
							children={
								<span>
									{choice.name}
									<br />
									{choice.description && (
										<span css={{ color: theme.palette.text.secondary, fontSize: 14 }}>{choice.description}</span>
									)}
								</span>
							}
						/>
					);
				})}
			</Select>
			{props?.helperText && <FormHelperText children={props?.helperText} />}
		</FormControl>
	);
};

const AppCustomInput = styled(InputBase)(({ theme }) => ({
	[`& .${inputBaseClasses.input}`]: {
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		fontSize: 16,
		lineHeight: "22px",
		padding: "12px",
		color: theme.palette.text.primary,
		// boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
		border: "1px solid rgba(12, 25, 23, 0.12)",
		borderRadius: 12,
		appearance: "none",
		transition: theme.transitions.create(["border"]),
		"&:focus": {
			border: "1px solid rgba(12, 25, 23, 1)",
		},
	},
}));

export const AppFlatSelect = (props: FlatSelectProps) => {
	const { label, choices, onChange, initialValue } = props;
	const [value, setValue] = useState(initialValue || props.value || "any");

	useEffect(() => {
		if (props.value && props.value !== value) setValue(props.value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.value]);

	const theme = useTheme();

	return (
		<FormControl disabled={props?.disabled} style={{ width: "100%", pointerEvents: props.disabled ? "none" : undefined }}>
			{label && (
				<InputLabel
					shrink={true}
					style={{
						fontSize: 16,
						fontWeight: "bold",
						whiteSpace: "nowrap",
						textTransform: "uppercase",
						color: "rgba(12, 25, 23, 0.38)",
					}}
					sx={{
						transform: "translate(0px, -1.5px) scale(0.75);",
						color: `${theme.palette.black.a38} !important`,
					}}
				>
					{label}
				</InputLabel>
			)}
			<Select
				disabled={props.disabled}
				onBlur={props.onBlur}
				sx={{
					marginTop: label ? 6 : 0,
					width: "100%",
					paddingTop: 0,
					paddingBottom: 0,
					"div > div > span": {
						display: "none",
					},
					svg: {
						right: 0,
						marginRight: "10px",
						fill: (theme) => theme.palette.text.primary,
					},
					[`& .${selectClasses.select}`]: {
						minHeight: "unset !important",
						letterSpacing: "normal",
					},
				}}
				value={value}
				onChange={(e) => {
					setValue(e.target.value as string);
					onChange && onChange(e.target.value as string);
				}}
				input={<AppCustomInput />}
				IconComponent={ExpandMoreIcon}
				displayEmpty
				MenuProps={{
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left",
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left",
					},
					// anchorEl: null,
				}}
			>
				{props?.displayEmpty && <StyledMenuItem value="" children="Seleziona un valore" />}
				{choices.map((choice, index) => {
					if (choice.hidden) return null;
					return (
						<StyledMenuItem
							key={`choice_${index}`}
							value={choice.value}
							children={
								<span>
									{choice.name}
									<br />
									{choice.description && (
										<span css={{ color: theme.palette.text.secondary, fontSize: 14 }}>{choice.description}</span>
									)}
								</span>
							}
						/>
					);
				})}
			</Select>
			{props?.helperText && <FormHelperText children={props?.helperText} />}
		</FormControl>
	);
};

export default FlatSelect;
